<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
        <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px; margin-left: 0;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
        </div></v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Book Type</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Book Type</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="bookType" outlined dense :rules="[rules.required]"
                                    clearable required></v-text-field>
                                <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                                <v-text-field class="text" type="number" v-model="displayorder" outlined
                                    dense></v-text-field>
                                <label class="add-text">Add Short Name</label>
                                <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                                <label class="add-text">Book Classification</label>
                                <v-select dense v-model="editedbookCL" :items="bookCl_list" item-value="bookClId"
                                    item-text="bookClName" persistent-hint outlined></v-select>
                                <v-btn color="success" @click="addBookType">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Book Type
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;"><br>
                                    <label class="add-text">Book Type</label>
                                    <v-text-field class="text" v-model="bookTypeName" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>
                                    <label class="add-text">Add Display Order</label>
                                    <v-text-field class="text" type="number" v-model="displayorder" outlined
                                        dense></v-text-field>
                                    <label class="add-text">Add Short Name</label>
                                    <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                                    <label class="add-text">Book Classification</label>
                                    <v-select dense v-model="editedbookCL" :items="bookCl_list" item-value="bookClId"
                                        item-text="bookClName" persistent-hint outlined></v-select>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="bookType_list != null">
                    <v-data-table :headers="headers" :items="bookType_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateBookType(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.bookTypeIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteBookType(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        loader: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        bookType: null,
        bookTypeId: null,
        bookTypeName: null,
        userType: null,
        edit_btn_load: false,
        editUserType: null,
        editUserTypeId: null,
        bookCl_list: [],
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Book Type',
                disabled: true,
                href: '',
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
        bookType_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "bookType" },
            { text: "Short Name", value: "shortname" },
            { text: "Book Classification", value: "cl" },
            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/bookTypeIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Book Type updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updateBookType(item) {
            console.log(item);
            this.dialog = true;
            this.bookTypeName = item.bookType;
            this.bookTypeId = item.bookTypeId;
            this.displayorder = item.displayorder;
            this.shortname = item.shortname;
            this.editedbookCL = item.clid;
        },

        saveEdit(item) {
            if (this.bookTypeName) {
                this.bookTypeName = this.bookTypeName.trim()
            }
            console.log(item);
            const data = {
                bookTypeName: this.bookTypeName,
                bookTypeId: this.bookTypeId,
                displayorder: this.displayorder,
                shortname: this.shortname,
                bookCL: this.editedbookCL
            };
            if(!this.bookTypeName){
                this.showSnackbar("#b71c1c", "Please Enter Book Type");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
           
                axios
                    .post("/admin/editBookType", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            // this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Book Type updated successfully...");
                            this.onload();
                            this.dialog = false;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
          
        },
        deleteBookType(item) {
            const data = {
                bookTypeId: item.bookTypeId,
            };
            axios
                .post("/admin/deleteBookType", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "BookType Deleted Successfully  !!");
                        this.onload();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getBookType")
                .then((res) => {
                    this.org = res.data.org;
                    this.bookType_list = res.data.bookType;
                    this.bookCl_list = res.data.bookCl_list;
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addBookType() {
            if (this.bookType) {
                this.bookType = this.bookType.trim()
            }

            const data = {
                bookType_name: this.bookType,
                displayorder: this.displayorder,
                shortname: this.shortname,
                bookCL: this.editedbookCL
            };

            if(!this.bookType){
                this.showSnackbar("#b71c1c", "Please Enter Book Type");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            
                axios
                    .post("/admin/addBookType", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.bookType_list = res.data.bookType_list;
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "Book Type added successfully...");
                            // this.userType = null;
                            this.onload();
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            this.$refs.form.validate()
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
           
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
